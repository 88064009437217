import React, { FC, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import {
  CarouselRoot,
  CarouselControls,
  CarouselState,
  CarouselPreviousButton,
  CarouselNextButton,
  UnstyledCarouselScrollArea,
  Flex,
  Box,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useFindLocaleText } from '../../text-localisation'
import { useSearchStore } from '../../store/SearchStore'
import {
  FiltersCarouselStickyWrapper,
  CarouselItem,
  FiltersMenuButton,
  AllFiltersButton,
} from './components'
import { createCarouselFilterItems } from './createCarouselFilterItems'
import { useNbaFilters } from '../../utils/nbaFilters'

/* istanbul ignore next */
const calculatePaddingLeft = ({
  useDropdownFilters,
  useNewGalleryUX,
}: {
  useDropdownFilters: boolean
  useNewGalleryUX: boolean
}) => {
  if (useDropdownFilters && useNewGalleryUX) {
    return { xs: 3, md: 4 }
  }
  if (useNewGalleryUX) {
    return { md: 2 }
  }
  if (useDropdownFilters) {
    return { xs: 4, lg: 8, xxl: 11 }
  }
  return 0
}

const StyledFlex = styled(Flex)<{
  useDropdownFilters: boolean
  useNewGalleryUX: boolean
}>`
  ${({ useDropdownFilters, useNewGalleryUX }) =>
    s({
      width: { xs: '100vw', md: '100%' },
      maxWidth: '100%',
      flexDirection: 'column',
      py: 4,
      mb: 6,
      pl: calculatePaddingLeft({ useDropdownFilters, useNewGalleryUX }),
      pr: useNewGalleryUX ? { xs: 0, md: 6 } : 0,
      bgcolor: 'colorBackground01',
    })}
`

const StyledCarouselRoot = styled(CarouselRoot)`
  ${s({
    px: 0,
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  gap: ${spacingPx(4)};
`

export const FiltersCarousel: FC = () => {
  const filtersfromStore = useSearchStore(store => store.filters)
  const filters = useNbaFilters(filtersfromStore || [])
  const useDropdownFilters =
    useExperiment('search-use-dropdown-filters')?.toLocaleLowerCase() ===
    'enabled'
  const useNewGalleryUX =
    useExperiment('search-new-gallery-ux-v2')?.toLocaleLowerCase() === 'enabled'
  const localiseText = useFindLocaleText()
  const [filtersButtonInView, setFiltersButtonInView] = useState(false)

  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const toggleFilter = useSearchStore(store => store.toggleFilter)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const resultsLoading = useSearchStore(store => store.resultsLoading)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const optimisticRender = useSearchStore(store => store.optimisticRender)
  const carouselItems = [
    <CarouselItem key="open-filters-button">
      {!useDropdownFilters && (
        <FiltersMenuButton
          onInView={setFiltersButtonInView}
          toggleMenu={toggleFiltersMenu}
          isMenuOpen={filtersMenuOpen}
          appliedFilters={selectedFilters}
        />
      )}
    </CarouselItem>,
    ...createCarouselFilterItems({
      filters,
      toggleFilter,
      resultsLoading,
      selectedFilters,
      optimisticRender,
      localiseText,
    }),
    <CarouselItem key="view-all-filters-button">
      <AllFiltersButton
        displayButton={
          !filtersMenuOpen && !resultsLoading && !filtersButtonInView
        }
        onFiltersClickedCallback={toggleFiltersMenu}
      />
    </CarouselItem>,
  ]

  return (
    <FiltersCarouselStickyWrapper>
      <StyledFlex
        data-testid="filters-carousel-variant"
        useDropdownFilters={useDropdownFilters}
        useNewGalleryUX={useNewGalleryUX}
      >
        {carouselItems.length > 0 ? (
          <CarouselState>
            <StyledCarouselRoot aria-label={localiseText('find.nba_filters')}>
              <Box display={{ xs: 'none', md: 'block' }}>
                <CarouselControls>
                  <CarouselPreviousButton />
                  <CarouselNextButton />
                </CarouselControls>
              </Box>
              <StyledCarouselScrollArea>
                {carouselItems}
              </StyledCarouselScrollArea>
            </StyledCarouselRoot>
          </CarouselState>
        ) : /* istanbul ignore next */ null}
      </StyledFlex>
    </FiltersCarouselStickyWrapper>
  )
}
