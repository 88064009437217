import { Experiments } from '@moonpig/web-core-experiments'
import { Region } from '@moonpig/web-core-types'
import { type AllFlagIDs } from '@moonpig/web-explore-flags'
import { getCardVariantIdsEnabled } from '@moonpig/web-shared-products'
import { SearchFeatures } from '../types/searchFeatures'

export type LoadFeaturesProps = {
  region: Region
  experiments: Experiments
  flags: Partial<Record<AllFlagIDs, boolean>>
}

const getExperimentString = (experiments: Experiments, key: string) => {
  if (experiments[key]?.toLowerCase() === 'enabled') {
    return `${key}=true`
  }
  if (experiments[key]?.toLowerCase() === 'original') {
    return `${key}=false`
  }
  return ''
}

export const loadFeatures: (props: LoadFeaturesProps) => SearchFeatures = ({
  experiments,
  flags,
}) => {
  const isFlagOn = (key: string) => flags[key as keyof typeof flags] ?? false
  const isExperimentEnabled = (key: string) =>
    experiments[key]?.toLowerCase() === 'enabled'

  const showCollageMaker = isFlagOn('search-show-collage-maker')
  const useNewFiltersApi = isFlagOn('search-use-new-filters-api')
  const excludeDirectSmile =
    experiments['gallery-direct-smile-impact'] === 'ExcludeDirectSmile'
  const showGiftVouchers = isExperimentEnabled('show-gift-vouchers')

  const cardVariants = getCardVariantIdsEnabled()

  const isMixedCategoryNbas = isExperimentEnabled('search-mixed-category-nbas')
  const nbaAlgorithm = isMixedCategoryNbas ? 'NBA_5' : 'NBA_4'

  const experimentValues = [
    'search-use-new-ia-index=true',
    getExperimentString(experiments, 'search-use-shared-ranking-v2'),
    getExperimentString(experiments, 'search-use-ai-generated-data'),
    getExperimentString(experiments, 'search-use-dropdown-filters'),
    getExperimentString(experiments, 'search-ranking-top-100'),
    getExperimentString(experiments, 'search-use-vector-boosted-ranking'),
  ]
    .filter(Boolean)
    .join('&')

  return {
    showGiftVouchers,
    excludeDirectSmile,
    searchUseNewIaIndex: true,
    showCollageMaker,
    cardVariants,
    experimentValues,
    nbaAlgorithm,
    useNewFiltersApi,
  }
}
