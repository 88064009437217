import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { useEffect, useRef } from 'react'
import { SearchFilter } from '../../services'

type UseWatchParamatersProps = {
  searchTerm: string
  filters: SearchFilter[]
  sort: SortOrder
  promotionId?: string
  loading: boolean
  callback: () => void
}

const filtersAreEqual = (first: SearchFilter[], second: SearchFilter[]) => {
  return (
    first.length === second.length &&
    (first.length === 0 ||
      (first.every(f =>
        second.some(s => s.key === f.key && s.group === f.group),
      ) &&
        second.every(s =>
          first.some(f => f.key === s.key && f.group === s.group),
        )))
  )
}

export const useWatchParameters: (props: UseWatchParamatersProps) => void = ({
  searchTerm,
  filters,
  sort,
  promotionId,
  loading,
  callback,
}) => {
  const currentFilters = useRef(filters)
  const currentSearchTerm = useRef(searchTerm)
  const currentPromotionId = useRef(promotionId)
  const currentSort = useRef(sort)

  useEffect(() => {
    if (
      !loading &&
      (!filtersAreEqual(filters, currentFilters.current) ||
        searchTerm !== currentSearchTerm.current ||
        promotionId !== currentPromotionId.current ||
        sort !== currentSort.current)
    ) {
      currentSearchTerm.current = searchTerm
      currentFilters.current = filters
      currentSort.current = sort
      currentPromotionId.current = promotionId
      callback()
    }
  }, [filters, searchTerm, sort, promotionId, loading, callback])
}
