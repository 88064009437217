import { SearchFilter } from '../../services/types'
import { FilterItem } from '../../services/types/services'

export const removePreAppliedFilters = (
  filters: FilterItem[],
  preAppliedFilters: SearchFilter[],
): FilterItem[] => {
  const nonUserAppliedFilterIds: string[] = preAppliedFilters
    .filter(filter => !filter.userApplied)
    .map(filter => filter.key)

  return filters.reduce((acc: FilterItem[], filter: FilterItem) => {
    if (filter.__typename === 'FilterCategory') {
      const updatedChildren = removePreAppliedFilters(
        filter.children,
        preAppliedFilters,
      )
      acc.push({ ...filter, children: updatedChildren })
    } else if (
      filter.__typename === 'Filter' &&
      !nonUserAppliedFilterIds.includes(filter.id)
    ) {
      acc.push(filter)
    } else if (filter.__typename !== 'Filter') {
      acc.push(filter)
    }
    return acc
  }, [])
}
