import { create } from 'zustand'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import type { PageStateProps, SearchStoreState } from './types'
import { FilterAction, FilteringEvent } from '../types'
import { trackingMiddleware } from './middleware/Tracking'
import { updateCategories } from './helpers/updateCategories'
import { updateSelectedFilters } from './helpers/updateSelectedFilters'
import { clearCategoryFilters } from './helpers/filterCategories'
import { updateAllCategoryFilters } from './helpers/updateAllCategoryFilters'
import { updateAllFilters } from './helpers/updateAllFilters'
import { updateRoute } from './helpers/updateRoute'
import { getFiltersById } from './helpers/getFiltersById'
import { removePreAppliedFilters } from './helpers/removePreAppliedFilters'
import { getPersistedFilters } from './helpers/persistFilterSelection'
import { applySelectedFilters as applyPreviouslySelectedFilters } from './helpers/applySelectedFilters'
import { ApplicableFilter, FilterItem } from '../services/types/services'
import { optimisticUpdatedFilterMapping } from './helpers/optimisticlyMapFilters'

export const useSearchStore = create<SearchStoreState>(
  trackingMiddleware((set, get) => ({
    pageContext: { title: '', type: '', router: null },
    filtersMenuOpen: false,
    resultsLoading: false,
    filters: [],
    preAppliedFilters: [],
    selectedFilters: [],
    filteringEvent: {} as FilteringEvent,
    selectedCategories: [],
    allCategoryFilters: [],
    queryFilters: null,
    results: { count: 0 },
    initialSortValue: SortOrder.POPULARITY,
    sortValue: SortOrder.POPULARITY,
    sortMenuOpen: false,
    useOptimisticRendering: false,
    optimisticRender: false,
    toggleFiltersMenu: source => {
      set(state => ({
        filtersMenuOpen: !state.filtersMenuOpen,
        selectedCategories: [],
        sortMenuOpen: source === 'sort',
      }))
    },
    toggleFiltersCategory: filtersCategory =>
      set(state => {
        const currentSelectedCategories = [...state.selectedCategories]
        const isCurrentCategory =
          filtersCategory.id ===
          currentSelectedCategories[currentSelectedCategories.length - 1]?.id

        if (isCurrentCategory) {
          currentSelectedCategories.pop()
        } else {
          currentSelectedCategories.push(filtersCategory)
        }

        return {
          selectedCategories: currentSelectedCategories,
        }
      }),
    toggleResultsLoading: () =>
      set({
        resultsLoading: true,
      }),
    createPageContext: ({ pageContext }: PageStateProps) =>
      set(() => ({ pageContext })),
    loadFilters: async ({
      query,
      initialFilters,
      urlFilters,
      initialSortValue,
      results,
      useOptimisticRendering,
    }) => {
      const filtersResult = await query([...initialFilters, ...urlFilters])
      const persistedFilters = getPersistedFilters()

      set(() => {
        return {
          filters: applyPreviouslySelectedFilters(
            removePreAppliedFilters(filtersResult.filters, initialFilters),
            [...persistedFilters, ...urlFilters.map(f => f.key)],
          ),
          preAppliedFilters: initialFilters,
          selectedFilters: getFiltersById({
            filterIds: [...urlFilters.map(f => f.key), ...persistedFilters],
            allFilters: filtersResult.filters,
          }),
          queryFilters: async filters => {
            const result = await query(filters)
            return removePreAppliedFilters(result.filters, initialFilters)
          },
          sortValue: initialSortValue,
          initialSortValue,
          results,
          useOptimisticRendering,
        }
      })
    },
    optimisticallyApplyFilters: async ({ filter, select }) => {
      const { selectedFilters, selectedCategories, filters } = get()

      const updatedSelectedFilters = updateSelectedFilters({
        selectedFilters,
        newFilter: filter,
        select,
      })

      const optimisticUpdatedFilters = filters.map(f =>
        optimisticUpdatedFilterMapping(f, filter, select),
      )

      const updatedCategories = updateCategories(
        optimisticUpdatedFilters,
        selectedCategories.map(c => c.id),
      )

      set(() => {
        return {
          selectedFilters: updatedSelectedFilters,
          filters: optimisticUpdatedFilters,
          selectedCategories: updatedCategories,
          optimisticRender: true,
        }
      })
    },

    toggleFilter: async ({ filter, select, source }) => {
      const {
        selectedFilters,
        queryFilters,
        toggleResultsLoading,
        preAppliedFilters,
        allCategoryFilters,
        selectedCategories,
        pageContext: { router },
        optimisticallyApplyFilters,
        useOptimisticRendering,
        filters,
      } = get()

      if (queryFilters) {
        if (useOptimisticRendering) {
          optimisticallyApplyFilters({ filter, select })
        }
        try {
          toggleResultsLoading()
          const updatedSelectedFilters = updateSelectedFilters({
            selectedFilters,
            newFilter: filter,
            select,
          })
          const updatedFilters = await queryFilters([
            ...updatedSelectedFilters.map(f => ({
              key: f.id,
              group: f.parent,
              userApplied: true,
            })),
            ...preAppliedFilters,
          ])

          const updatedCategories = updateCategories(
            updatedFilters,
            selectedCategories.map(c => c.id),
          )
          updateRoute({ router, selectedFilters: updatedSelectedFilters })
          set(() => {
            return {
              filters: updatedFilters,
              allCategoryFilters: updateAllFilters({
                allCategoryFilters,
                select,
                category: updatedCategories[selectedCategories.length - 1],
              }),
              selectedFilters: updatedSelectedFilters,
              select,
              filteringEvent: {
                action: select ? FilterAction.Add : FilterAction.Remove,
                source,
                filter,
              },
              selectedCategories: updatedCategories,
              optimisticRender: false,
            }
          })
        } catch {
          if (useOptimisticRendering) {
            set(() => {
              return {
                resultsLoading: false,
                selectedFilters,
                selectedCategories,
                filters,
                optimisticRender: false,
              }
            })
          }
        }
      }
    },

    optimisticallyApplyAllFilters: async ({ filter, select }) => {
      const {
        selectedFilters,
        selectedCategories,
        allCategoryFilters,
        filters,
      } = get()

      const updatedSelectedFilters = updateAllCategoryFilters({
        selectedFilters,
        categoryFilters:
          selectedCategories[selectedCategories.length - 1].children,
        select,
      })

      const optimisticUpdatedFilters = filters.map(f =>
        optimisticUpdatedFilterMapping(f, filter, select),
      )
      set(state => {
        return {
          filters: optimisticUpdatedFilters,
          allCategoryFilters: select
            ? [...allCategoryFilters, filter.id]
            : allCategoryFilters.filter(f => f !== filter.id),
          selectedFilters: updatedSelectedFilters,
          selectedCategories: updateCategories(
            optimisticUpdatedFilters,
            state.selectedCategories.map(c => c.id),
          ),
          optimisticRender: true,
        }
      })
    },

    toggleAllCategoryFilters: async ({ filter, select, source }) => {
      const {
        selectedFilters,
        queryFilters,
        toggleResultsLoading,
        preAppliedFilters,
        selectedCategories,
        allCategoryFilters,
        pageContext: { router },
        optimisticallyApplyAllFilters,
        filters,
        useOptimisticRendering,
      } = get()

      if (queryFilters) {
        if (useOptimisticRendering) {
          optimisticallyApplyAllFilters({ filter, select })
        }
        toggleResultsLoading()
        try {
          const updatedSelectedFilters = updateAllCategoryFilters({
            selectedFilters,
            categoryFilters:
              selectedCategories[selectedCategories.length - 1].children,
            select,
          })
          const updatedFilters = await queryFilters([
            ...updatedSelectedFilters.map(f => ({
              key: f.id,
              group: f.parent,
              userApplied: true,
            })),
            ...preAppliedFilters,
          ])
          updateRoute({ router, selectedFilters: updatedSelectedFilters })
          set(state => {
            return {
              filters: updatedFilters,
              allCategoryFilters: select
                ? [...allCategoryFilters, filter.id]
                : allCategoryFilters.filter(f => f !== filter.id),
              selectedFilters: updatedSelectedFilters,
              select,
              filteringEvent: {
                action: select ? FilterAction.Add : FilterAction.Remove,
                source,
                filter,
              },
              selectedCategories: updateCategories(
                updatedFilters,
                state.selectedCategories.map(c => c.id),
              ),
              optimisticRender: false,
            }
          })
        } catch {
          if (useOptimisticRendering) {
            set(() => {
              return {
                resultsLoading: false,
                selectedFilters,
                selectedCategories,
                filters,
                optimisticRender: false,
              }
            })
          }
        }
      }
    },
    updateResults: results => set(() => ({ results, resultsLoading: false })),
    clearFilters: async ({ category, type, source }) => {
      const {
        queryFilters,
        toggleResultsLoading,
        preAppliedFilters,
        selectedFilters,
        pageContext: { router },
        allCategoryFilters,
        filters,
      } = get()

      let updatedFilters: FilterItem[] | null = null
      let updatedSelectedFilters: ApplicableFilter[] | null = null

      if (queryFilters) {
        if (type !== 'sort') {
          toggleResultsLoading()
          const persistedFilters = getFiltersById({
            filterIds: getPersistedFilters(),
            allFilters: filters,
          })
          updatedSelectedFilters = category
            ? clearCategoryFilters(category, [...selectedFilters])
            : persistedFilters

          updatedFilters = await queryFilters([
            ...preAppliedFilters,
            ...updatedSelectedFilters.map(f => ({
              key: f.id,
              group: f.parent,
              userApplied: true,
            })),
          ])

          updateRoute({ router, selectedFilters: updatedSelectedFilters })
        }

        set(state => {
          return {
            filters: updatedFilters || filters,
            selectedFilters: updatedSelectedFilters || selectedFilters,
            allCategoryFilters: category
              ? updateAllFilters({
                  allCategoryFilters,
                  select: false,
                  category,
                })
              : [],
            filteringEvent: {
              action: category ? FilterAction.Clear : FilterAction.ClearAll,
              filter: category,
              source,
            },
            selectedCategories: updatedFilters
              ? updateCategories(
                  updatedFilters,
                  state.selectedCategories.map(c => c.id),
                )
              : state.selectedCategories,
            sortValue: category ? state.sortValue : state.initialSortValue,
          }
        })
      }
    },
    sortBy: value => set(() => ({ sortValue: value })),
  })),
)
