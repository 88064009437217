/* eslint-disable camelcase */
import { Page, PageContext } from '@moonpig/web-core-app'
import { FC } from 'react'
import { Routes } from '@moonpig/web-core-routing'
import {
  ColumnWidthVariant,
  DepartmentsEnum,
  ImagePlacement,
  ModulePlaceholderVariant,
} from '@moonpig/web-explore-types-graphql'
import { DispatchCutOff } from '@moonpig/web-shared-products'
import { ModuleHeadingGenerated } from './modules/Heading'
import { ModuleBreadcrumbsGenerated } from './modules/Breadcrumbs'
import { ModuleTilesGenerated } from './modules/Tiles'
import { ModuleInfoGenerated } from './modules/Info'
import {
  LinkActionFragment as LinkActionGenerated,
  AddMembershipToBasketActionFragment as AddMembershipToBasketActionGenerated,
} from './modules/CtaAction/__generated__/fragment'
import { ModuleSEOGenerated } from './modules/SEO'
import { ModuleEmailCaptureGenerated } from './modules/EmailCapture'
import { ModuleMissionButtonsGenerated } from './modules/MissionButtons'
import { ModuleHeroGenerated, ModuleDynamicHeroGenerated } from './modules/Hero'
import { ModulePlacardsGenerated } from './modules/Placards'
import { ModuleUspCarouselGenerated } from './modules/USPCarousel'
import { ModuleCardGenerated } from './modules/Card'
import { ModuleTabbedProductListsGenerated } from './modules/TabbedProductLists'
import { DynamicHeroResponse } from './services/dataInference'
import { ModuleRecommendationsForCustomerGenerated } from './modules/RecommendationsForCustomer'

export type Image = {
  url: string
  description?: string
  dimensions: {
    width: number
    height: number
  }
}

export type PlacardImage = {
  urlPrimary: string
  urlDefault: string
  description?: string
  dimensions: {
    width: number
    height: number
  }
}

export type TileImage = {
  urlLightPrimary: string
  urlLightDefault: string
  urlDarkPrimary: string
  urlDarkDefault: string
  description: string
  dimensions: {
    width: number
    height: number
  }
}

export type ModuleType<T extends { __typename: string }> = T & {
  type: T['__typename']
}

export type ModuleHeading = ModuleType<ModuleHeadingGenerated>

export type ModuleBreadcrumbs = ModuleType<ModuleBreadcrumbsGenerated>

export type ModuleInfo = ModuleType<ModuleInfoGenerated>

export type ModuleSEO = ModuleType<ModuleSEOGenerated>

export type ModuleEmailCapture = ModuleType<ModuleEmailCaptureGenerated>

export type ModuleMissionFinder = {
  type: 'ModuleMissionFinder'
}

export type ModuleAction =
  | LinkActionGenerated
  | AddMembershipToBasketActionGenerated

export type ModuleHero = ModuleType<ModuleHeroGenerated>

export type ModuleDynamicHero = ModuleType<ModuleDynamicHeroGenerated>

export type ModulePlacards = ModuleType<ModulePlacardsGenerated>

export type ModuleMissionButtons = ModuleType<ModuleMissionButtonsGenerated>

export type ModuleUspCarousel = ModuleType<ModuleUspCarouselGenerated>

export type ModuleCard = ModuleType<ModuleCardGenerated>

export type ModuleRecommendationsForCustomer =
  ModuleType<ModuleRecommendationsForCustomerGenerated>

export type ModuleTabbedProductLists =
  ModuleType<ModuleTabbedProductListsGenerated>

export type ModuleHomeDynamicHero = {
  defaultHero: ModuleHero
  dynamicHeroList: ModuleDynamicHero[]
  type: 'ModuleHomeDynamicHero'
  dynamicHeroData: DynamicHeroResponse
}

export type ModuleOfferStrip = {
  type: 'ModuleOfferStrip'
  text: string
}

export type ModuleDescription = {
  type: 'ModuleDescription'
  description: string
}

export type ModuleSearchResults = {
  type: 'ModuleSearchResults'
  url: string
  title: string
  subtitle: string
  departments: string[]
  alternativeButton?: boolean
  keywords: string
  facetDetails: {
    group: string | null
    key: string | null
    userApplied?: boolean
  }[]
  sponsoredProducts: {
    internallyPromoted: boolean
    productKey: string
  }[]
  promotionId?: string
}

export type ModulePromoTile = {
  type: 'ModulePromoTile'
  name: string
  title: string
  description: string
  internallyPromoted: boolean
  image: Image
  cta: {
    title: string
    action: ModuleAction
  }
}

export type ModuleDeliveryStrip = {
  type: 'ModuleDeliveryStrip'
  text: string
  deliveryStripCta: {
    title: string
    url: string
    shouldFormat: boolean | null
  } | null
}

export type ModuleCTAStrip = {
  type: 'ModuleCTAStrip'
  text: string
  backgroundImage?: Image | null
  cta: {
    title: string
    url: string
    shouldFormat: boolean | null
  }
  meta: {
    variation?: {
      key: string
      value: string
    } | null
  }
  banner: boolean
}

export type LabelPromo = {
  text: string
  type: 'LabelPromo'
}

export type LabelRoundel = {
  prefix: string
  top: string
  bottom: string
  type: 'LabelRoundel'
}

export type Label = LabelRoundel | LabelPromo

export type ModuleTiles = ModuleType<ModuleTilesGenerated>

export type ModuleTwoColumnPlacard = {
  type: 'ModuleTwoColumnPlacard'
  title: string
  /* Source should be escaped on content-api before usage */
  unsafeBody?: string
  body?: string
  image: PlacardImage
  columnWidthVariant: ColumnWidthVariant
  imagePlacement: ImagePlacement
  twoColumnPlacardCta?: {
    title: string
    url: string
  } | null
}

export type ModuleVideo = {
  type: 'ModuleVideo'
  id: string
  title: string
  titleHidden: boolean
  subtitle: string
}

export type ModuleRichTextImage = {
  type: 'ModuleRichTextImage'
  image: Image
  altText: string
}

export type ModuleRichText = {
  type: 'ModuleRichText'
  blocks: RichTextBlock[]
}

type RichTextBlock = RichTextBlockHTML | RichTextBlockModule

type RichTextBlockHTML = {
  type: 'RichTextBlockHTML'
  html: string
}

type RichTextBlockModule = {
  type: 'RichTextBlockModule'
  module: ContentModule
}

export type ModuleBlogPostDate = {
  type: 'ModuleBlogPostDate'
  date: string
}

export type ModuleBlogPostAuthor = {
  type: 'ModuleBlogPostAuthor'
  name: string
  jobTitle: string
  image: Image
}

export type ModuleBlogPostCategory = {
  type: 'ModuleBlogPostCategory'
  category: string
  url: string
}

export type ModuleHeroList = {
  type: 'ModuleHeroList'
  items: {
    title: string
    subtitle: string
    image: {
      url: string
    }
    ctas?: {
      title: string
      url?: string
    }[]
  }[]
}

export type ModuleRecentlyViewed = {
  type: 'ModuleRecentlyViewed'
  trackingDataProps: {
    pageLocation: string
  }
}

export type BlogTile = {
  title: string
  subtitle: string
  url: string
  image: Image
}

export type USPIcon = {
  urlCompact: string
  urlEnlarged: string
  description: string
  dimensions: {
    height: number
    width: number
  }
} | null

export type ModuleBlogPostsListing = {
  type: 'ModuleBlogPostsListing'
  title?: string
  items?: BlogTile[]
  category?: string
}

export type ModuleBlogHomepageImage = {
  type: 'ModuleBlogHomepageImage'
  image?: Image
}

export type ModuleBlogPostsCategories = {
  type: 'ModuleBlogPostsCategories'
  items?: Partial<ModuleBlogPostCategory>[]
}

export type ModulePlaceholder = {
  type: 'ModulePlaceholder'
  placeholderVariant: ModulePlaceholderVariant
  backgroundColor?: string
  pageLocation?: string
  trackingDataProps?: {
    pageLocation: string
    moduleIndex: number
    modulesLength: number
  }
}

export type ContentModule =
  | ModuleHeading
  | ModuleBreadcrumbs
  | ModuleHero
  | ModuleDynamicHero
  | ModuleInfo
  | ModuleMissionButtons
  | ModuleOfferStrip
  | ModuleDeliveryStrip
  | ModuleCTAStrip
  | ModuleTiles
  | ModulePlacards
  | ModuleSEO
  | ModuleDescription
  | ModuleSearchResults
  | ModulePromoTile
  | ModuleTabbedProductLists
  | ModuleMissionFinder
  | ModuleVideo
  | ModuleRichText
  | ModuleBlogPostDate
  | ModuleBlogPostAuthor
  | ModuleBlogPostCategory
  | ModuleHeroList
  | ModuleRecentlyViewed
  | ModuleBlogPostsListing
  | ModuleBlogHomepageImage
  | ModuleBlogPostsCategories
  | ModuleRichTextImage
  | ModuleRecommendationsForCustomer
  | ModulePlaceholder
  | ModuleTwoColumnPlacard
  | ModuleHomeDynamicHero
  | ModuleUspCarousel
  | ModuleEmailCapture
  | ModuleCard

type ContentData = {
  modules: ContentModule[]
  dynamicHeroData: DynamicHeroResponse
}

type GetInitialProps<T> = Exclude<Page<T>['getInitialProps'], undefined>
type ResolveValue<T> = T extends Promise<infer U> ? U : T
type GetInitialPropsReturn<T> = ResolveValue<ReturnType<GetInitialProps<T>>>

// eslint-disable-next-line @typescript-eslint/ban-types
export type PageContent<TProps = {}> = FC<TProps> & {
  getInitialProps(
    context: PageContext<Routes['content']> & ContentData,
  ): Promise<GetInitialPropsReturn<{ props: TProps }>>
}

export enum FilterSource {
  Gallery = 'gallery',
  NextBestAction = 'nba',
  Filter = 'filter',
  FiltersMenu = 'filters menu',
  Toggle = 'toggle',
  QuickFilters = 'quick-filters',
}

export enum FilterAction {
  Add = 'add',
  Remove = 'remove',
  Clear = 'clear',
  ClearAll = 'clear all',
}

export type FilteringEvent = {
  action: FilterAction
  source: FilterSource
  filter?: { id: string; parent: string }
}

export type ApplyFiltersDetails = {
  action: FilterAction
  sender: FilterSource
  senderDetails?: string[]
}

export type DepartmentInfo = {
  longDeliveryInfo: string
  promotionBanner?: string | null
  dispatchCutOff: DispatchCutOff
}

export type AlternativeDepartmentDetails = {
  department: DepartmentsEnum
  count: number
}

export type Product = {
  id: string
  slug: string
  title: string
  customisable: boolean
  category: {
    id: number
    slug: string
    name: string
    department: DepartmentsEnum
  }
  masterVariant: {
    title: string
    images: {
      url: string
    }[]
    price: {
      centAmount: number
      fractionDigits: number
      currencyCode: string
    }
    fullPrice: {
      centAmount: number
      fractionDigits: number
      currencyCode: string
    }
    discountedPercentage?: number | null
    inStock: boolean
  }
  rating?: {
    score: number
    count?: number
  } | null
  isFavourite?: boolean
  isSponsored: boolean
}

export type ViewedProduct = {
  title: string
  image: string
  url: string
  id: string
}

export type TrackingData = {
  moduleIndex: number
  moduleType: string
  experimentVariantName: string
  brand: string
}

export type RecentlyViewedProduct = {
  url: string
  id: string
}

export type RecentlyViewedProductEnriched = RecentlyViewedProduct & {
  title: string
  image: string
  masterVariant: {
    title: string
    price: {
      currencyCode: string
      centAmount: number
    }
    inStock: boolean
  }
  isFavourite?: boolean
  category: {
    name: string
    department: DepartmentsEnum
  }
  isSponsored: boolean
}

export type ContinueJourneyProduct = {
  url: string
  id: string
  title: string
  image: string
  masterVariant: {
    title: string
    price: {
      currencyCode: string
      centAmount: number
    }
    inStock: boolean
  }
  category: {
    name: string
    department: DepartmentsEnum
  }
  isDraftProduct?: boolean
  isSponsored: boolean
}

export type Breadcrumb = {
  url: string
  label: string
}

export type PageMeta = {
  title: string
  description: string
  canonicalSubPath: string
  noIndex: boolean
  openGraph: {
    url: string
    title: string
    description: string
    image: {
      url: string
    }
    locale: string
    siteName: string
    type: string | null
  }
  structuredData: {
    json: string
  }[]
}

export type NextBestActionFacet = {
  id: string
  nbaScore: number
  group: string
  facetKey: string
  label: string
}

export type FlatFilterFacet = {
  group: string
  facetKey: string
  label: string
  count?: number
  nbaScore?: number
  isSelected?: boolean
  hasAllOption?: boolean
}
